.analytics-container {
    padding-top: 80px;
    min-height: 100vh;
    background-color: #f5f5f5;
  }
  
  .analytics-card {
    transition: all 0.3s ease;
    border-radius: 12px !important;
    overflow: hidden;
  }
  
  .analytics-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1) !important;
  }
  
  .chart-container {
    background: white;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    position: relative;
    overflow: hidden;
  }
  
  .application-status-section,
  .counselor-status-section {
    background: white;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  }
  
  .chart-container .recharts-legend-wrapper {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .chart-container .recharts-legend-item {
    padding: 4px 8px;
    margin: 2px 0;
    border-radius: 4px;
    transition: background-color 0.2s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .chart-container .recharts-legend-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  
  .chart-container .recharts-pie-sector {
    transition: opacity 0.3s, transform 0.3s;
  }
  
  .chart-container .recharts-pie-sector:hover {
    opacity: 0.8;
    transform: scale(1.03);
  }
  
  .status-filter-button {
    margin: 4px 0;
    transition: all 0.2s ease;
    min-width: 100px;
    text-transform: none !important;
    justify-content: flex-start !important;
    padding: 8px 12px !important;
  }
  
  .status-filter-button:hover {
    transform: translateY(-1px);
  }
  
  .status-group-button {
    margin: 4px 0;
    transition: all 0.2s ease;
    text-transform: none !important;
    justify-content: flex-start !important;
    padding: 8px 12px !important;
    width: 100%;
  }
  
  .status-group-button:hover {
    transform: translateY(-1px);
  }
  
  .status-tooltip {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 600px) {
    .chart-container {
      padding: 16px;
    }
    
    .application-status-section {
      padding: 16px !important;
    }
  
    .chart-container .recharts-legend-wrapper {
      position: relative !important;
      width: 100% !important;
      height: auto !important;
      margin-top: 10px;
      padding: 8px;
    }
  
    .status-filter-button,
    .status-group-button {
      min-width: auto;
      padding: 6px 10px !important;
      font-size: 0.75rem;
    }
  
    .status-group-button {
      width: 100%;
    }
  }
  
  .table-container {
    background: white;
    border-radius: 12px;
    overflow: hidden;
  }
  
  
  .table-row-hover:hover {
    background-color: #f8f9fa !important;
  }
  
  .action-cell {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .action-create {
    background-color: #e8f5e9;
    color: #2e7d32;
  }
  
  .action-update {
    background-color: #e3f2fd;
    color: #1976d2;
  }
  
  .action-delete {
    background-color: #ffebee;
    color: #d32f2f;
  }
  
  .action-delete_user {
    background-color: #ffebee;
    color: #d32f2f;
  }
  
  .action-check_status {
    background-color: #f3e5f5;
    color: #7b1fa2;
  }
  
  .action-update_invoice_status {
    background-color: #e3f2fd;
    color: #1976d2;
  }
  
  .action-import {
    background-color: #9966ff;
    color: white;
  }
  
  .action-export {
    background-color: #66cc66;
    color: white;
  }
  
  .action-print {
    background-color: #ff9933;
    color: white;
  }
  
  .action-unknown {
    background-color: #f5f5f5;
    color: #757575;
  }
  
  .action-update_package {
    background-color: #e3f2fd;
    color: #1976d2;
  }
  
  /* Value cells styling */
  .old-value {
    color: #ff4d4d;
    text-decoration: line-through;
    margin-right: 8px;
  }
  
  .new-value {
    color: #4CAF50;
    font-weight: 500;
  }
  
  .date-picker-container {
    background: white;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }
  
  .section-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #1976d2;
  } 