/* Add these at the very top of the file */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.dashboard-container {
  padding: 2rem;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e9f2 100%);
  padding-top: 6rem;
  position: relative;
  width: 100%;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1.5rem;
  border-radius: 10px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.dashboard-title {
  font-size: 2.2rem;
  font-weight: 600;
  background: linear-gradient(45deg, #1a73e8, #34a853);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

.logout-btn {
  padding: 0.5rem 1.5rem;
  transition: all 0.3s ease;
}

.logout-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.upload-section {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
  border-top: 4px solid #1a73e8;
}

.upload-label {
  font-size: 1.1rem;
  color: #1a73e8;
  margin-bottom: 1rem;
  font-weight: 500;
}

.upload-input {
  border: 2px dashed #1a73e8;
  padding: 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.upload-input:hover {
  border-color: #34a853;
  background-color: #f8f9fa;
}

.table-container {
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-top: 4px solid #34a853;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.modern-table {
  width: 100%;
  table-layout: auto;
}

.modern-table thead {
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  z-index: 1;
}

.modern-table thead th {
  background-color: #f8f9fa;
  border: none;
  padding: 0.5rem;
  font-weight: 600;
  color: #1a73e8;
  text-align: left;
  white-space: nowrap;
  position: relative;
}

.modern-table tbody td {
  border: none;
  border-bottom: 1px solid #f1f1f1;
  padding: 0.5rem;
  vertical-align: middle;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Column-specific widths */
.modern-table th:nth-child(1),
.modern-table td:nth-child(1) {
  max-width: 250px;
  width: auto;
  white-space: normal;
  word-wrap: break-word;
}

.modern-table th:nth-child(2),
.modern-table td:nth-child(2) {
  max-width: 200px;
  width: auto;
  white-space: normal;
  word-wrap: break-word;
}

.modern-table th:nth-child(3),
.modern-table td:nth-child(3) {
  width: 120px;
}

/* Remove fixed width for counselor column to make it flexible */
.modern-table th[data-label="Counselor"],
.modern-table td[data-label="Counselor"] {
  width: 120px;
  min-width: 120px;
  white-space: normal;
  word-wrap: break-word;
}

/* Adjust other column widths to be more flexible */
.modern-table th:not([data-label="Counselor"]):nth-child(n+4),
.modern-table td:not([data-label="Counselor"]):nth-child(n+4) {
  width: auto;
  min-width: 120px;
}

/* Status column */
.modern-table th[data-label="Status"],
.modern-table td[data-label="Status"] {
  width: 180px;
  min-width: 180px;
}

/* Progress column */
.modern-table th[data-label="Progress"],
.modern-table td[data-label="Progress"] {
  width: 120px;
  min-width: 120px;
}

/* Last Updated column */
.modern-table th[data-label="Last Updated"],
.modern-table td[data-label="Last Updated"] {
  width: 120px;
  min-width: 120px;
  text-align: center;
}

/* Payment Info column */
.modern-table th[data-label="Payment Info"],
.modern-table td[data-label="Payment Info"] {
  width: 120px;
  min-width: 120px;
  text-align: center;
}

/* Student Year column */
.modern-table th[data-label="Student Year"],
.modern-table td[data-label="Student Year"] {
  width: 120px;
  min-width: 120px;
  text-align: center;
}

/* Actions column */
.modern-table th:last-child,
.modern-table td:last-child {
  width: 120px;
  min-width: 120px;
  text-align: right;
}

/* Make sure the table header text is centered for these columns */
.modern-table thead th:nth-child(7),
.modern-table thead th:nth-child(8),
.modern-table thead th:nth-child(9) {
  text-align: center;
}

/* Update dropdown button styles */
.dropdown-toggle.btn {
  width: 90px;
  text-align: center;
  padding: 4px 8px;
  font-size: 0.875rem;
}

/* Center align the dropdown menu */
.dropdown-menu {
  min-width: 90px;
  text-align: center;
}

/* Date time container adjustments */
.date-time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.date {
  font-weight: 500;
  margin-bottom: 2px;
  font-size: 0.875rem;
}

.time {
  font-size: 0.8rem;
  color: #666;
}

/* Name cell specific styles */
.modern-table td:nth-child(1) .d-flex {
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: flex-start;
}

.modern-table td:nth-child(1) .nationality-badge {
  margin-top: 0.25rem;
  white-space: nowrap;
}

.nationality-badge {
  background: #e8f0fe;
  color: #1a73e8;
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;

}

.status-badge {
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  display: inline-block;
  white-space: normal;
  text-align: left;
  line-height: 1.2;
  max-width: 200px;
  word-wrap: break-word;
}

.status-approved {
  background: #e6f4ea;
  color: #34a853;
}

.status-in-progress {
  background: #fef7e0;
  color: #fbbc04;
}

.mb-3 {
  margin-bottom: 1rem;
}

.me-2 {
  margin-right: 0.5rem;
}

.text-center {
  text-align: center;
  padding: 20px;
  color: #666;
}

.alert {
  padding: 12px 20px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.table {
  margin-top: 20px;
}

.dashboard-container {
  padding: 2rem;
  min-height: calc(100vh - 136px);
  /* Adjust based on your navbar and footer height */
}

.table-responsive {
  margin-top: 1rem;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  margin: 2rem auto;
}

/* Add these utility classes if you're not using Bootstrap's utilities */
.mb-4 {
  margin-bottom: 1.5rem;
}

.me-2 {
  margin-right: 0.5rem;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.table-header h3 {
  margin: 0;
  color: #1a73e8;
  font-weight: 500;
}

.add-student-btn {
  background-color: #1a73e8;
  border: none;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
}

.add-student-btn:hover {
  background-color: #2d9248;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button-group {
  display: flex;
  gap: 1rem;
}

.update-status-btn {
  background-color: #34a853;
  border: none;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
}

.update-status-btn:hover {
  background-color: #2d9248;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.progress {
  height: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  height: 100%;
  transition: width 0.3s ease;
}

.progress-text {
  position: relative;
  left: 0;
  right: 0;
  top: 50%;
  text-align: center;
  color: #000;
  font-size: 0.8rem;
  font-weight: 500;
  z-index: 1;
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.5);

}

/* Mobile Card View */
@media (max-width: 768px) {
  .dashboard-container {
    padding: 1rem;
    padding-top: 5rem;
  }

  .table-container {
    padding: 0;
    margin-top: 1rem;
    background: transparent;
    box-shadow: none;
    border: none;
  }

  .modern-table thead {
    display: none;
  }

  .modern-table tbody {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
  }

  .modern-table tbody tr {
    display: inline-flex;
    flex-direction: column;
    background: white;
    padding: 1.25rem;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    width: calc(50% - 0.5rem);
    min-width: 280px;
    flex-grow: 1;
  }

  .modern-table tbody td {
    display: block;
    padding: 0.75rem 0;
    border: none;
    text-align: left;
    white-space: normal;
    width: 100% !important;
  }

  .modern-table tbody td>div {
    width: 100%;
  }

  .modern-table tbody td::before {
    content: attr(data-label);
    font-weight: 500;
    color: #666;
    display: block;
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  .nationality-badge {
    display: inline-block;
    margin-top: 0.5rem;
  }

  .d-flex {
    width: 100%;
    justify-content: space-between;
  }

  .d-flex .nationality-badge {
    margin-top: 0;
    margin-left: 0.5rem;
  }

  .status-badge {
    display: inline-block;
    width: 100%;
    text-align: left;
    margin: 0;
  }

  .mobile-progress-container {
    width: 100%;
  }

  .progress {
    width: 100%;
    height: 8px;
    margin-top: 0.5rem;
  }

  .progress-text {
    margin-bottom: 0.25rem;
    font-size: 0.9rem;
    font-weight: 500;
    width: 100%;
    text-align: left;
  }

  .action-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
    width: 100%;
  }

  .action-buttons .btn {
    width: 100%;
    padding: 0.75rem;
    font-size: 0.9rem;
    white-space: nowrap;
    margin: 0;
  }

  /* Remove bottom borders except for the last section */
  .modern-table tbody td:not(:last-child) {
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 1rem;
  }
}

/* Additional responsive breakpoints for card sizing */
@media (max-width: 640px) {
  .modern-table tbody tr {
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .modern-table tbody tr {
    width: calc(33.333% - 0.667rem);
  }
}

@media (min-width: 1201px) {
  .modern-table tbody tr {
    width: calc(25% - 0.75rem);
  }
}

/* Make the actions column take only the space it needs */
.modern-table td:last-child {
  width: 1%;
  white-space: nowrap;
}

/* Action buttons container */
.action-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

/* Individual action button styling */
.action-buttons .btn {
  white-space: nowrap;
  padding: 0.375rem 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

/* Search and Filter Styles */
.search-filter-container {
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
}

.search-filter-container .form-control,
.search-filter-container .form-select {
  border: 1px solid #e0e0e0;
  padding: 0.6rem 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
}

.search-filter-container .form-control:focus,
.search-filter-container .form-select:focus {
  border-color: #1a73e8;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.1);
  background-color: white;
}

.search-filter-container .form-control::placeholder {
  color: #6c757d;
  opacity: 0.8;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .search-filter-container {
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .search-filter-container .row>[class*='col-'] {
    margin-bottom: 0.75rem;
  }

  .search-filter-container .row>[class*='col-']:last-child {
    margin-bottom: 0;
  }

  .search-filter-container .form-control,
  .search-filter-container .form-select {
    font-size: 0.9rem;
    padding: 0.5rem 0.8rem;
  }
}

.issue-card {
  background-color: #fff;
  transition: all 0.3s ease;
}

.issue-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.status-update {
  border-left-width: 3px !important;
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 0 4px 4px 0;
  margin-left: 1rem;
}

.status-update:last-child {
  margin-bottom: 0 !important;
}

.issue-header {
  transition: all 0.2s ease;
  padding: 0.5rem;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.issue-header:hover {
  background-color: #f8f9fa;
}

.issue-header-content {
  flex: 1;
  margin-right: 1rem;
  min-width: 0; /* This ensures text truncation works */
}

.issue-header h5 {
  font-size: 1rem;
  font-weight: 600;
  color: #1a73e8;
  margin-bottom: 0.25rem;
}

.latest-status {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.latest-comment {
  font-size: 0.875rem;
  color: #666;
  margin-top: 0.5rem;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: flex;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.issue-header .badge {
  font-size: 0.75rem;
  padding: 0.35rem 0.65rem;
}

.issue-header .text-muted {
  font-size: 0.75rem;
}

.collapse-icon {
  color: #6c757d;
  transition: transform 0.2s ease;
  padding: 0.25rem;
  margin-left: 0.5rem;
  flex-shrink: 0;
}

.collapse-icon i {
  transform-origin: center;
  transition: transform 0.3s ease;
}

.collapse-icon i.rotated {
  transform: rotate(180deg);
}

.issue-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  opacity: 0;
}

.issue-content.show {
  max-height: 2000px;
  /* Adjust based on your content */
  opacity: 1;
  transition: max-height 0.5s ease-in, opacity 0.3s ease-in;
}

/* Remove the old collapse classes that we won't use anymore */
.issue-content.collapse:not(.show),
.issue-content.collapsing {
  display: block;
}

.issue-content {
  transition: all 0.3s ease;
}

.issue-content.collapse:not(.show) {
  display: none;
}

.issue-content.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}

.issue-content.show {
  display: block;
}

.modern-table .btn-warning {
  background-color: #fbbc04;
  border: none;
  color: #000;
}

.modern-table .btn-warning:hover {
  background-color: #f9ab00;
  color: #000;
}


/* Student Management Styles */
.student-table {
  width: 100%;
  overflow-x: auto;
}

.student-table th {
  background-color: #f8f9fa;
  font-weight: 500;
  color: #0d6efd;
  padding: 1rem;
  white-space: nowrap;
}

.student-table td {
  padding: 1rem;
  vertical-align: middle;
}

.student-actions {
  width: 100%;
  min-width: 280px;
  max-width: 320px;
}

.student-action-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  width: 100%;
}

.student-action-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  font-size: 0.875rem;
  border-radius: 6px;
  transition: all 0.2s ease;
  width: 100%;
  min-width: 120px;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.student-action-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.student-action-btn i {
  margin-right: 0.5rem;
  font-size: 0.875rem;
  flex-shrink: 0;
}

/* Action Button Variants */
.btn-edit {
  background-color: #4287f5;
  color: white;
}

.btn-edit:hover {
  background-color: #2d6ed9;
}

.btn-deactivate {
  background-color: #ffc107;
  color: #000;
}

.btn-deactivate:hover {
  background-color: #e0a800;
}

.btn-activate {
  background-color: #28a745;
  color: white;
}

.btn-activate:hover {
  background-color: #218838;
}

.btn-change-owner {
  background-color: #17a2b8;
  color: white;
}

.btn-change-owner:hover {
  background-color: #138496;
}

.btn-delete {
  background-color: #dc3545;
  color: white;
}

.btn-delete:hover {
  background-color: #c82333;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .student-table {
    display: block;
    width: 100%;
  }

  .student-table thead {
    display: none;
  }

  .student-table tbody,
  .student-table tr,
  .student-table td {
    display: block;
    width: 100%;
  }

  .student-table tr {
    margin-bottom: 1rem;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 1rem;
    background: #fff;
  }

  .student-table td {
    padding: 0.5rem 0;
    text-align: left;
    border: none;
  }

  .student-table td::before {
    content: attr(data-label);
    font-weight: 500;
    color: #6c757d;
    display: block;
    margin-bottom: 0.25rem;
  }

  .student-actions {
    min-width: unset;
    max-width: unset;
    width: 100%;
    padding: 0;
  }

  .student-action-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    margin-top: 0.5rem;
  }

  .student-action-btn {
    min-width: unset;
    padding: 0.625rem;
    font-size: 0.875rem;
  }

  .student-action-btn i {
    margin-right: 0.5rem;
    font-size: 0.875rem;
  }

  /* Keep text visible on mobile */
  .student-action-btn span {
    display: inline;
  }
}

/* Tablet Styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .student-actions {
    min-width: 240px;
    max-width: 280px;
  }

  .student-action-btn {
    padding: 0.4rem;
    font-size: 0.8125rem;
  }
}

/* Desktop Styles */
@media (min-width: 1025px) {
  .student-actions {
    min-width: 280px;
    max-width: 320px;
  }

  .student-action-btn {
    padding: 0.5rem;
    font-size: 0.875rem;
  }
}

/* Status Badges */
.status-badge {
  padding: 0.4rem 0;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
  display: inline-block;
}

.active-badge {
  background-color: #28a745;
  color: white;
  padding: 0.4rem 0.5rem;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
  text-align: left;
}

.inactive-badge {
  background-color: #dc3545;
  color: white;
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
  text-align: left;
}

/* Progress Bar */
.progress {
  height: 20px;
  background-color: #e9ecef;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar {
  background-color: #0d6efd;
  color: white;
  text-align: center;
  line-height: 20px;
  font-size: 0.85rem;
  font-weight: 500;
  transition: width 0.3s ease;
}

/* Inactive Row */
.inactive-row {
  background-color: #f8f9fa;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .student-action-btn {
    padding: 0.4rem 0.8rem;
    font-size: 0.85rem;
  }
}

@media (max-width: 992px) {
  .student-actions {
    min-width: 240px;
  }

  .student-action-btn {
    padding: 0.3rem 0.6rem;
  }
}

/* Remove these old styles as they're no longer needed */
.modern-table th:nth-child(2),
.modern-table td:nth-child(2),
.modern-table th:nth-child(3),
.modern-table td:nth-child(3),
.modern-table th:nth-child(4),
.modern-table td:nth-child(4),
.modern-table th:nth-child(5),
.modern-table td:nth-child(5),
.modern-table th:nth-child(6),
.modern-table td:nth-child(6) {
  min-width: auto;
}

/* Make table container scrollable horizontally on small screens */
.table-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 1rem 1.5rem;
}

/* Responsive adjustments */
@media screen and (max-width: 1200px) {
  .modern-table tbody td {
    min-width: 100px;
  }

  .modern-table th:nth-child(7),
  .modern-table td:nth-child(7) {
    min-width: 180px;
  }
}

@media screen and (max-width: 768px) {
  .modern-table tbody td {
    min-width: 80px;
  }

  .modern-table th:nth-child(1),
  .modern-table td:nth-child(1) {
    min-width: 150px;
  }

  .modern-table th:nth-child(7),
  .modern-table td:nth-child(7) {
    min-width: 150px;
  }
}

/* Action buttons column styling - remove text-align */
.modern-table th:last-child {
  min-width: 280px;
  width: auto;
  white-space: nowrap;
}

.modern-table td:last-child {
  width: auto;
  white-space: nowrap;
}

/* Action buttons container */
.action-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

/* Individual action button styling */
.action-buttons .btn {
  min-width: 80px;
  padding: 0.375rem 0.75rem;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Responsive adjustments for action buttons */
@media screen and (max-width: 1200px) {
  .action-buttons .btn {
    min-width: 70px;
    padding: 0.375rem 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .modern-table th:last-child,
  .modern-table td:last-child {
    min-width: 220px;
  }

  .action-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
  }

  .action-buttons .btn {
    min-width: 60px;
    font-size: 0.875rem;
  }
}

/* Invoice status styles */
.invoice-status-pending.btn {
    background: #ffc107 !important;
    color: #000 !important;
    border: none !important;
}

.invoice-status-invoiced.btn {
    background: #6f42c1 !important;
    color: #fff !important;
    border: none !important;
}

.invoice-status-paid.btn {
    background: #28a745 !important;
    color: #fff !important;
    border: none !important;
}

.invoice-status-pending.btn:hover,
.invoice-status-invoiced.btn:hover,
.invoice-status-paid.btn:hover,
.invoice-status-pending.btn:focus,
.invoice-status-invoiced.btn:focus,
.invoice-status-paid.btn:focus,
.invoice-status-pending.btn:active,
.invoice-status-invoiced.btn:active,
.invoice-status-paid.btn:active {
    opacity: 0.9;
    border: none !important;
    box-shadow: none !important;
}

.invoice-status-pending.btn:hover {
    background: #ffc107 !important;
    color: #000 !important;
}

.invoice-status-invoiced.btn:hover {
    background: #6f42c1 !important;
    color: #fff !important;
}

.invoice-status-paid.btn:hover {
    background: #28a745 !important;
    color: #fff !important;
}

.dropdown-menu {
    min-width: 120px;
}

.dropdown-item {
    cursor: pointer;
    padding: 0.5rem 1rem;
}

.dropdown-item:hover {
    background-color: #f8f9fa;
}

/* Table styles to handle dropdown overflow */
.table-container {
    overflow: visible !important;
}

.table-responsive {
    overflow: visible !important;
}

.modern-table {
    overflow: visible !important;
}

.modern-table td {
    overflow: visible !important;
}

.modern-table tbody {
    overflow: visible !important;
}

.modern-table tr {
    overflow: visible !important;
}

.modern-table thead th,
.modern-table tbody td,
.modern-table td:last-child,
.modern-table th:last-child {
  text-align: left;
}


/* Remove any conflicting alignment styles */
.modern-table th:last-child {
  min-width: 280px;
  width: auto;
  white-space: nowrap;
}

.modern-table td:last-child {
  width: auto;
  white-space: nowrap;
}

/* Date Time styles */
.date-time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.date {
  font-weight: 500;
  margin-bottom: 2px;
}

.time {
  font-size: 0.9em;
  color: #666;
}

/* React Select Styles */
.basic-multi-select .select__control {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  min-height: 38px;
  background-color: #f8f9fa;
}

.basic-multi-select .select__control:hover {
  border-color: #1a73e8;
}

.basic-multi-select .select__control--is-focused {
  border-color: #1a73e8;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.1);
  background-color: white;
}

.basic-multi-select .select__menu {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.basic-multi-select .select__option {
  padding: 8px 12px;
}

.basic-multi-select .select__option--is-focused {
  background-color: #f8f9fa;
}

.basic-multi-select .select__option--is-selected {
  background-color: #1a73e8;
}

.basic-multi-select .select__multi-value {
  background-color: #e8f0fe;
  border-radius: 4px;
}

.basic-multi-select .select__multi-value__label {
  color: #1a73e8;
  font-size: 0.85em;
}

.basic-multi-select .select__multi-value__remove {
  color: #1a73e8;
}

.basic-multi-select .select__multi-value__remove:hover {
  background-color: #d2e3fc;
  color: #1a73e8;
}

.basic-multi-select .select__placeholder {
  color: #6c757d;
}

/* Mobile Styles for React Select */
@media (max-width: 768px) {
  .basic-multi-select .select__control {
    font-size: 0.9rem;
    min-height: 36px;
  }

  .basic-multi-select .select__multi-value {
    font-size: 0.85rem;
  }
}

/* Unviewed update highlight */
.unviewed-update {
    background-color: rgba(26, 115, 232, 0.08) !important;
    border-left: 4px solid #1a73e8 !important;
    transition: background-color 0.3s ease;
}

.unviewed-update:hover {
    background-color: rgba(26, 115, 232, 0.12) !important;
}

/* Section Headers */
.section-header {
    background-color: transparent;
    margin: 1.5rem 0 0.5rem 0;
}

.section-header-cell {
    padding: 1rem !important;
    font-weight: 600;
    color: #1a73e8;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    background: #e8f0fe;
    border-radius: 8px;
}

.section-header-cell.no-updates {
    color: #666;
    background: #f8f9fa;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    margin-top: 2rem;
}

/* Mobile styles for section headers */
@media (max-width: 768px) {
    .section-header {
        margin: 1.5rem -1rem 0.5rem -1rem;
    }

    .section-header-cell {
        display: block !important;
        width: 100% !important;
        text-align: left !important;
        padding: 0.75rem 1rem !important;
        border-radius: 0;
    }
}

/* Reset Filters Button */
.reset-filters-btn {
  width: 38px;
  height: 38px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.reset-filters-btn:hover {
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
  transform: rotate(-45deg);
}

.reset-filters-btn i {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .reset-filters-btn {
    width: 34px;
    height: 34px;
  }
  
  .reset-filters-btn i {
    font-size: 0.9rem;
  }
}

.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip-wrapper:hover .custom-tooltip {
  visibility: visible;
  opacity: 1;
}

.custom-tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  white-space: pre-line;
  z-index: 1000;
  min-width: 200px;
  text-align: center;
  transition: opacity 0.2s ease-in-out;
}

.custom-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

/* Ensure the table row can show the tooltip */
.modern-table tr {
  position: relative;
}

/* Ensure tooltips appear above other elements */
.modern-table {
  position: relative;
  z-index: 1;
}

.modern-table tr:hover {
  z-index: 2;
}

/* Package status styles */
.package-status-Free.btn {
    background: #6c757d !important;
    color: #fff !important;
    border: none !important;
}

.package-status-100.btn {
    background: #17a2b8 !important;
    color: #fff !important;
    border: none !important;
}

.package-status-300.btn {
    background: #fd7e14 !important;
    color: #fff !important;
    border: none !important;
}

.package-status-500.btn {
    background: #9c27b0 !important;
    color: #fff !important;
    border: none !important;
}

.package-status-Free.btn:hover,
.package-status-100.btn:hover,
.package-status-300.btn:hover,
.package-status-500.btn:hover,
.package-status-Free.btn:focus,
.package-status-100.btn:focus,
.package-status-300.btn:focus,
.package-status-500.btn:focus,
.package-status-Free.btn:active,
.package-status-100.btn:active,
.package-status-300.btn:active,
.package-status-500.btn:active {
    opacity: 0.9;
    border: none !important;
    box-shadow: none !important;
}

.package-status-Free.btn:hover {
    background: #6c757d !important;
    color: #fff !important;
}

.package-status-100.btn:hover {
    background: #17a2b8 !important;
    color: #fff !important;
}

.package-status-300.btn:hover {
    background: #fd7e14 !important;
    color: #fff !important;
}

.package-status-500.btn:hover {
    background: #9c27b0 !important;
    color: #fff !important;
}

/* Payment Info cell styles */
.payment-info-cell {
  width: 120px !important;
  text-align: center;
  padding: 0.75rem 0.5rem !important;
}

.payment-buttons-stack {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.payment-buttons-stack .dropdown {
  width: 100%;
}

.payment-buttons-stack .dropdown-toggle {
  width: 100%;
  min-width: 90px;
}

/* Update column widths for the new structure */
.modern-table th:nth-child(8),
.modern-table td:nth-child(8) {
  width: 120px;
  text-align: center;
}

/* Actions column */
.modern-table th:nth-child(9),
.modern-table td:nth-child(9) {
  width: 250px;
  text-align: right;
}

/* Make sure the table header text is centered for payment info */
.modern-table thead th:nth-child(8) {
  text-align: center;
}

/* Adjust dropdown button styles */
.dropdown-toggle.btn {
  text-align: center;
  padding: 4px 8px;
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Ensure dropdowns stack properly on mobile */
@media (max-width: 768px) {
  .payment-info-cell {
    width: 100% !important;
  }
  
  .payment-buttons-stack {
    gap: 0.75rem;
  }
  
  .payment-buttons-stack .dropdown-toggle {
    width: 120px;
  }
}

/* Action buttons styling */
.action-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.action-buttons .dropdown {
  width: 100%;
}

.action-buttons .dropdown-toggle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #495057;
  transition: all 0.2s ease-in-out;
  font-size: 0.875rem;
}

.action-buttons .dropdown-toggle:hover,
.action-buttons .dropdown-toggle:focus {
  background-color: #e9ecef;
  border-color: #ced4da;
  color: #212529;
  box-shadow: none;
}

.action-buttons .dropdown-toggle::after {
  margin-left: 0.5rem;
}

/* Fix dropdown menu positioning */
.action-buttons .dropdown {
  position: relative;
}

.action-buttons .dropdown-menu {
  position: absolute;
  transform-origin: top;
  margin: 0;
  padding: 0.5rem 0;
  min-width: 160px;
  z-index: 1000;
}

/* Ensure dropdown is always visible */
.action-buttons .dropdown-menu.dropdown-menu-end {
  right: 0;
  left: auto !important;
}

/* Add max-height and scrolling for very long dropdowns */
.action-buttons .dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}

/* Ensure proper spacing and alignment of dropdown items */
.action-buttons .dropdown-item {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  clear: both;
}

/* Fix table row hover behavior */
.modern-table tbody tr {
  position: relative;
}

.modern-table tbody tr:hover {
  z-index: auto !important;
}

/* Mobile styles for action buttons */
@media (max-width: 768px) {
  .action-buttons {
    width: 100%;
  }

  .action-buttons .dropdown-toggle {
    width: 100%;
    padding: 0.625rem 1rem;
    font-size: 0.875rem;
  }

  .action-buttons .dropdown-menu {
    width: 100%;
    position: fixed !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0;
    top: auto !important;
    transform: none !important;
    max-height: 50vh;
    overflow-y: auto;
    margin: 0;
    border-radius: 1rem 1rem 0 0;
    border-width: 1px 0 0 0;
  }

  .action-buttons .dropdown-item {
    padding: 1rem;
    font-size: 1rem;
  }

  .action-buttons .dropdown-item i {
    font-size: 1.125rem;
  }
}

/* Remove any conflicting styles */
.modern-table td:last-child {
  width: 120px;
  min-width: 120px;
  position: relative;
}

/* Update the table cell width for actions */
.modern-table th:last-child,
.modern-table td:last-child {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

/* Remove old z-index styles that might conflict */
.modern-table {
  position: relative;
  z-index: 1;
}

.modern-table tr:hover {
  z-index: auto;
}

/* Ensure proper stacking context */
.table-container {
  position: relative;
  z-index: 1;
}

/* Override any Bootstrap default z-index values */
.dropdown-menu.show {
  z-index: 1050 !important;
}

.action-buttons .btn {
  min-width: unset;
  padding: unset;
  white-space: unset;
  display: unset;
  align-items: unset;
  justify-content: unset;
}

.badge {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: 500;
  display: inline-block;
  white-space: normal;
  text-align: left;
  line-height: 1.2;
  max-width: 200px;
  word-wrap: break-word;
}

.bg-success-dark {
  background-color: #145a32 !important;
  color: white !important;
}

/* Record count styles */
.record-count {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
}

.record-count span {
  display: inline-block;
  background: #e8f0fe;
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  color: #1a73e8;
  font-weight: 500;
  font-size: 0.85rem;
  border: 1px solid #d2e3fc;
}

@media (max-width: 768px) {
  .record-count span {
    font-size: 0.8rem;
    padding: 0.35rem 0.7rem;
  }
}

/* Student Year column */
.modern-table th:nth-child(9),
.modern-table td:nth-child(9) {
  width: 120px;
  text-align: center;
}

/* Invoice Note styles */
.invoice-note-tooltip {
  max-width: 300px;
  white-space: pre-wrap;
  word-break: break-word;
}

.invoice-status-pending.btn[title]:not([title=""]):hover::after,
.invoice-status-invoiced.btn[title]:not([title=""]):hover::after,
.invoice-status-paid.btn[title]:not([title=""]):hover::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: pre-wrap;
  max-width: 200px;
  z-index: 1000;
}

/* Mobile styles for invoice note and student year */
@media (max-width: 768px) {
  .modern-table td[data-label="Student Year"],
  .modern-table td[data-label="Payment Info"] {
    text-align: left;
    padding: 0.75rem 0;
  }

  .invoice-status-pending.btn[title]:not([title=""]):hover::after,
  .invoice-status-invoiced.btn[title]:not([title=""]):hover::after,
  .invoice-status-paid.btn[title]:not([title=""]):hover::after {
    position: fixed;
    bottom: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/* Invoice Note Modal styles */
.modal-content {
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.25rem 1.5rem;
}

.modal-header .modal-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a73e8;
}

.modal-body {
  padding: 1.5rem;
}

.modal-footer {
  border-top: 1px solid #e9ecef;
  padding: 1.25rem 1.5rem;
}

/* Invoice Note textarea styles */
#invoiceNote {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 0.75rem;
  font-size: 0.95rem;
  resize: vertical;
  min-height: 100px;
  transition: all 0.2s ease;
}

#invoiceNote:focus {
  border-color: #1a73e8;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.1);
  outline: none;
}

#invoiceNote::placeholder {
  color: #6c757d;
  opacity: 0.7;
}

/* Invoice Note label styles */
.form-label {
  font-weight: 500;
  color: #495057;
  margin-bottom: 0.5rem;
}

/* Mobile styles for invoice note modal */
@media (max-width: 768px) {
  .modal-content {
    margin: 1rem;
    border-radius: 8px;
  }

  .modal-header {
    padding: 1rem;
  }

  .modal-body {
    padding: 1rem;
  }

  .modal-footer {
    padding: 1rem;
  }

  #invoiceNote {
    font-size: 0.9rem;
    padding: 0.625rem;
  }
}

/* Student Files Modal styles */
.list-group-item {
  transition: all 0.2s ease;
  border-left: 3px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.list-group-item:hover {
  background-color: #f8f9fa;
  border-left-color: #1a73e8;
}

.list-group-item h6 {
  color: #1a73e8;
  margin-bottom: 0.25rem;
  word-wrap: break-word;
  word-break: break-all;
  max-width: calc(100% - 100px); /* Leave space for the download button */
}

.list-group-item small {
  color: #6c757d;
  word-wrap: break-word;
  word-break: break-all;
  max-width: 100%;
}

.list-group-item .btn {
  opacity: 0.8;
  transition: all 0.2s ease;
  flex-shrink: 0;
}

.list-group-item:hover .btn {
  opacity: 1;
}

/* Mobile styles for files modal */
@media (max-width: 768px) {
  .list-group-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .list-group-item h6 {
    max-width: 100%;
    margin-right: 0;
  }

  .list-group-item .btn {
    width: 100%;
  }
}

/* Style for sub-agent name */
.sub-agent-name {
  font-size: 0.9em;
  display: block;
  margin-top: 0.25rem;
  font-style: italic;
}

.existing-sub-agent {
  color: #1a73e8;
}

.other-sub-agent {
  color: #666;
}

/* Export button styles */
.export-btn {
  color: white;
  background-color: #17a2b8;
  border: none;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
}

.export-btn:hover {
  background-color: #138496;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.export-btn:disabled {
  background-color: #6c757d;
  transform: none;
  box-shadow: none;
}

/* Print button styles */
.print-btn {
  background-color: #6c757d;
  border: none;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
}

.print-btn:hover {
  background-color: #5a6268;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.print-btn:disabled {
  background-color: #adb5bd;
  transform: none;
  box-shadow: none;
}