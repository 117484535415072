.profile-container {
  max-width: 900px;
  margin: 2rem auto;
  padding: 20px;
  min-height: calc(100vh - 4rem);
}

.profile-card {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.08),
    0 1px 2px rgba(0, 0, 0, 0.02);
  border-radius: 24px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  position: relative;
}

.profile-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 8px;
  background: linear-gradient(90deg, #3b82f6, #60a5fa);
}

.profile-card:hover {
  transform: translateY(-4px);
  box-shadow: 
    0 12px 40px rgba(0, 0, 0, 0.12),
    0 2px 4px rgba(0, 0, 0, 0.04);
}

.profile-card .card-header {
  background: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 2rem;
  position: relative;
  color: #1a365d;
  font-size: 1.75rem;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.profile-card .card-header::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 2rem;
  width: 50px;
  height: 3px;
  background: linear-gradient(90deg, #3b82f6, #60a5fa);
  border-radius: 3px;
}

.profile-card .card-body {
  padding: 2.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
  position: relative;
}

.form-label {
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 0.75rem;
  display: block;
  font-size: 0.95rem;
}

.form-control {
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  padding: 0.875rem 1rem;
  font-size: 1rem;
  transition: all 0.2s ease;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
}

.form-control:hover {
  border-color: #cbd5e0;
}

.form-control:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.1);
  outline: none;
}

.btn {
  padding: 0.875rem 1.75rem;
  border-radius: 12px;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.3px;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.btn-primary {
  background: linear-gradient(135deg, #3b82f6 0%, #60a5fa 100%);
  border: none;
  color: white;
}

.btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
}

.btn-primary:active {
  transform: translateY(0);
}

.profile-section {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(226, 232, 240, 0.7);
}

.profile-section-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.loading {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-size: 1000px 100%;
}

@media (max-width: 768px) {
  .profile-container {
    padding: 1rem;
    margin: 0;
  }
  
  .profile-card {
    border-radius: 16px;
  }
  
  .profile-card .card-header {
    padding: 1.5rem;
    font-size: 1.4rem;
  }
  
  .profile-card .card-body {
    padding: 1.5rem;
  }
  
  .btn {
    width: 100%;
    padding: 0.75rem 1rem;
  }
  
  .profile-section {
    padding: 1rem;
  }
} 