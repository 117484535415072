.navbar-custom {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    padding: 0.5rem 0;
    user-select: none;
  }
  
  .brand-text {
    font-weight: 600;
    color: #2c3e50;
    font-size: 1.3rem;
  }
  
  .nav-link-custom {
    color: #6c757d !important;
    font-weight: 500;
    padding: 0.5rem 1rem !important;
    transition: all 0.3s ease;
    position: relative;
  }
  
  .nav-link-custom:hover {
    color: #2c3e50 !important;
  }
  
  .nav-link-custom::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background-color: #0d6efd;
    transition: all 0.3s ease;
  }
  
  .nav-link-custom:hover::after {
    width: 80%;
    left: 10%;
  }
  
  .navbar-container {
    max-width: 100% !important;
    padding: 0 2rem;
  }
  
  .navbar-custom .navbar-collapse {
    justify-content: space-between;
  }
  
  .nav-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .nav-right {
    margin-left: auto;
  }
  
  @media (max-width: 991px) {
    .nav-center {
      position: static;
      transform: none;
    }
    
    .nav-right {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
  
  @media (max-width: 768px) {
    .navbar-custom {
      padding: 0.5rem;
    }

    .navbar-container {
      padding: 0 1rem;
    }

    .brand-text {
      font-size: 1.1rem;
    }

    .navbar-collapse {
      background: white;
      padding: 1rem;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      margin-top: 0.5rem;
    }

    .nav-link-custom {
      padding: 0.8rem !important;
      border-radius: 6px;
    }

    .nav-link-custom:hover {
      background-color: #f8f9fa;
    }

    .nav-right {
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: 1px solid #eee;
    }
  }

  .admin-link {
    color: #1a73e8 !important;
    font-weight: 500;
  }

  .admin-link:hover {
    color: #0d47a1 !important;
  }

  .admin-badge {
    background-color: #1a73e8;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.75rem;
    font-weight: 500;
  }

  .nav-link-custom.active {
    color: #1a73e8 !important;
  }

  .nav-link-custom.active::after {
    width: 80%;
    left: 10%;
  }