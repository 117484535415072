/* Custom styles for the application */
body {
    background-color: #f1f3f4; /* Light gray */
    color: #202124; /* Dark gray text */
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
  }
  
  
  .btn-primary {
    background-color: #1a73e8;
    border-color: #1a73e8;
  }
  
  .btn-primary:hover {
    background-color: #1558c7;
  }
  
  .card {
    border: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .card-header {
    background-color: #1a73e8;
    color: white;
    font-size: 18px;
    text-align: center;
  }
  
  .table th {
    background-color: #1a73e8;
    color: white;
  }

  /* Status badge styles */
  .badge {
    font-size: 0.85rem;
    font-weight: normal;
    padding: 6px 12px;
    border-radius: 4px;
    white-space: normal;
    text-align: left;
    display: inline-block;
    line-height: 1.4;
    max-width: 200px;
  }

  .badge.bg-success {
    background-color: #0f9d58 !important;
  }

  .badge.bg-warning {
    background-color: #f4b400 !important;
    color: #000;
  }

  .badge.bg-info {
    background-color: #00b8d4 !important;
    color: #fff;
  }

  .badge.bg-secondary {
    background-color: #5f6368 !important;
  }

  /* Action buttons grid */
  .action-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    min-width: 200px;
  }

  .action-buttons .btn {
    width: 100%;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.375rem 0.5rem;
  }
  
  .navbar-custom .container {
    max-width: 100%;
  }
  