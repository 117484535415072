.admin-layout {
  display: flex;
  height: 100%;
  min-height: calc(100vh - 90px);
  position: relative;
  margin-bottom: 56px;
}

.admin-container {
  min-height: calc(100vh - 57px);
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: #f5f7fa;
}

.admin-sidebar {
  width: 280px;
  background: white;
  padding: 1.5rem 1rem;
  position: relative;
  top: calc(56px + 1rem);
  bottom: 3.4rem;
  overflow-y: auto;
  border-radius: 12px;
  margin: 0 1rem;
  margin-bottom: 4.4rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.sidebar-header {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e0e0e0;
}

.sidebar-header h4 {
  margin: 0;
  color: #1a73e8;
  font-size: 1.25rem;
  font-weight: 600;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu-item {
  width: 100%;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  border: none;
  background: none;
  border-radius: 8px;
  color: #5f6368;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.95rem;
  transform: translateZ(0);
  will-change: background-color;
  transition: background-color 0.2s ease, color 0.2s ease;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}

.sidebar-menu-item:hover {
  background: #f8f9fa;
  color: #1a73e8;
}

.sidebar-menu-item.active {
  background: #e8f0fe;
  color: #1a73e8;
}

.sidebar-menu-item i {
  margin-right: 0.75rem;
  width: 20px;
  text-align: center;
  font-size: 1rem;
  transform: translateZ(0);
}

.admin-main-content {
  flex: 1;
  margin-left: 250px;
  padding: 1.5rem 2rem;
  overflow-y: auto;
}

.admin-content {
  flex: 1;
  position: relative;
  margin-top: 70px;
  max-width: calc(100% - 310px);
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 146px);
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-shrink: 0;
}

.admin-header h2 {
  margin: 0;
  color: #1a73e8;
  font-size: 1.5rem;
  font-weight: 500;
}

.table-responsive {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  margin: -1.5rem;
  overflow: auto;
}

.user-table {
  margin-bottom: 0;
  width: 100%;
}

.user-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f8f9fa;
}

.user-table thead th {
  background-color: #f8f9fa;
  border: none;
  padding: 1rem 1.5rem;
  font-weight: 500;
  color: #5f6368;
  text-align: left;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.user-table tbody td {
  padding: 1rem 1.5rem;
  border: none;
  border-bottom: 1px solid #f1f1f1;
  vertical-align: middle;
  background-color: white;
  color: #3c4043;
}

.user-table tbody tr:hover td {
  background-color: #f8f9fa;
}

.role-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  font-size: 0.875rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}

.role-badge.admin {
  background-color: #e8f0fe;
  color: #1a73e8;
}

.role-badge.user {
  background-color: #f1f3f4;
  color: #5f6368;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

.btn-sm {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 6px;
}

.btn-outline-danger:hover {
  background-color: #dc3545;
  color: white;
}

.modal-content {
  border-radius: 8px;
  border: none;
}

.modal-header {
  border-bottom: 1px solid #f1f1f1;
  padding: 1.5rem;
}

.modal-body {
  padding: 1.5rem;
}

.form-control {
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  padding: 0.5rem 1rem;
}

.form-control:focus {
  border-color: #1a73e8;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.2);
}

.form-check-input:checked {
  background-color: #1a73e8;
  border-color: #1a73e8;
}

@media (max-width: 768px) {
  .admin-container {
    padding: 0;
  }

  .admin-layout {
    flex-direction: column;
    min-height: calc(100vh - 56px);
  }

  .admin-sidebar {
    width: 100%;
    position: relative;
    top: 0;
    margin: 0;
    padding: 1rem;
    border-radius: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  .admin-content {
    margin-left: 0;
    padding: 1rem;
    max-width: 100%;
  }

  .admin-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .admin-sidebar {
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 1rem;
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
  }

  .admin-main-content {
    margin-left: 0;
    padding: 1rem;
  }

  .admin-menu {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .admin-menu-item {
    margin-bottom: 0;
  }

  .admin-menu-link {
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
  }

  .admin-content {
    padding: 1rem;
  }

  .admin-header {
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;
    text-align: center;
    padding: 0 1rem;
  }

  .table-responsive {
    margin: -1rem;
  }

  .user-table thead th,
  .user-table tbody td {
    padding: 0.75rem 1rem;
  }

  .action-buttons {
    flex-direction: column;
  }

  .action-buttons .btn {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .admin-container {
    padding: 4rem 0.5rem 0.5rem 0.5rem;
  }

  .admin-content {
    padding: 0.75rem;
  }

  .table-responsive {
    margin: -0.75rem;
  }

  .user-table thead th,
  .user-table tbody td {
    padding: 0.75rem;
  }
}

.student-table {
  width: 100%;
  table-layout: auto;
}

.student-table th,
.student-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
}

/* Make the actions column take only the space it needs */
.student-table .col-actions {
  width: 1%;
  white-space: nowrap;
}

/* Active column should be compact */
.student-table .col-active {
  width: 1%;
  white-space: nowrap;
}

/* Make sure the action buttons stay in one line */
.student-action-grid {
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.student-action-btn {
  white-space: nowrap;
  padding: 4px 8px;
  font-size: 0.875rem;
  flex-shrink: 0;
}

/* New Admin Table Styles */
.admin-table-container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.admin-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.admin-table-header h3 {
  margin: 0;
  color: #1a73e8;
  font-size: 1.5rem;
  font-weight: 500;
}

.admin-table {
  margin-bottom: 0;
}

.admin-table thead th {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
  color: #495057;
  font-weight: 600;
  padding: 1rem;
  vertical-align: middle;
}

.admin-table tbody td {
  padding: 1rem;
  vertical-align: middle;
  border-bottom: 1px solid #dee2e6;
}

.admin-table tbody tr:hover {
  background-color: #f8f9fa;
}

.admin-table-actions {
  white-space: nowrap;
  width: 1%;
}

/* Responsive styles for the admin table */
@media (max-width: 768px) {
  .admin-table-container {
    padding: 1rem;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .admin-table-header {
    flex-direction: column;
    gap: 1rem;
  }

  .admin-table-header h3 {
    font-size: 1.25rem;
  }

  .admin-table {
    display: block;
    width: 100%;
  }

  .admin-table-actions {
    width: auto;
  }

  .admin-table-actions .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }

  /* Stack buttons vertically on very small screens */
  @media (max-width: 576px) {
    .admin-table-actions .d-flex {
      flex-direction: column;
      gap: 0.5rem;
    }

    .admin-table-actions .btn {
      width: 100%;
    }
  }
}

/* Badge styles */
.badge {
  padding: 0.5em 0.75em;
  font-weight: 500;
  border-radius: 4px;
}

/* Form control styles */
.form-control:focus {
  border-color: #1a73e8;
  box-shadow: 0 0 0 0.2rem rgba(26, 115, 232, 0.25);
}

/* Button styles */
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 4px;
}

.btn-primary {
  background-color: #1a73e8;
  border-color: #1a73e8;
}

.btn-primary:hover {
  background-color: #1557b0;
  border-color: #1557b0;
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

/* Search input styles */
.admin-table-header .form-control {
  max-width: 300px;
  border-radius: 4px;
}

/* Status badge styles */
.badge.bg-success {
  background-color: #28a745 !important;
}

.badge.bg-danger {
  background-color: #dc3545 !important;
}

.badge.bg-warning {
  background-color: #ffc107 !important;
  color: #000;
}

.badge.bg-info {
  background-color: #17a2b8 !important;
}

/* Table loading state */
.table-loading {
  text-align: center;
  padding: 2rem;
}

.table-loading .spinner-border {
  width: 3rem;
  height: 3rem;
  color: #1a73e8;
}

/* Empty state styles */
.table-empty-state {
  text-align: center;
  padding: 2rem;
  color: #6c757d;
}

.table-empty-state i {
  font-size: 2rem;
  margin-bottom: 1rem;
}

/* Permission badge styles */
.badge.bg-info {
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  background-color: #e8f0fe !important;
  color: #1a73e8;
  border: 1px solid #1a73e8;
}

.admin-table-header {
  margin-bottom: 1.5rem;
}

.admin-table-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.admin-table-title h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.admin-table-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 63%;
}

.admin-table-controls .search-section {
  display: flex;
  gap: 0.5rem;
  flex: 1;
  min-width: 300px;
}

.admin-table-controls .action-section {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.admin-table-controls .form-control,
.admin-table-controls .form-select {
  max-width: 250px;
}

/* Import dropdown button styles */
.import-dropdown .dropdown-toggle {
  background-color: #1a73e8;
  border: none;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.import-dropdown .dropdown-menu {
  padding: 1rem;
  min-width: 300px;
}

.import-dropdown .upload-section {
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  margin: 0.5rem 0;
}

.import-dropdown .last-import-info {
  font-size: 0.875rem;
  color: #6c757d;
  padding: 0.5rem 1rem;
  border-top: 1px solid #dee2e6;
  margin-top: 0.5rem;
}

.import-dropdown .dropdown-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
}

.import-dropdown .dropdown-item i {
  width: 1rem;
  text-align: center;
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .admin-table-controls {
    flex-direction: column;
    gap: 1rem;
  }

  .admin-table-controls .search-section,
  .admin-table-controls .action-section {
    width: 100%;
  }

  .admin-table-controls .form-control,
  .admin-table-controls .form-select {
    max-width: none;
  }
}

.form-select {
    background-image: var(--dropdown-arrow-svg);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
}

.form-check-input[type="radio"] {
    background-image: var(--radio-circle-svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

:root {
    --dropdown-arrow-svg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    --radio-circle-svg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}

/* SVG container styles to prevent layout shift */
.css-tj5bde-Svg {
    min-height: 20px;
    min-width: 20px;
    display: block;
    aspect-ratio: 1;
}

/* Footer styles to prevent layout shift */
.footer {
    min-height: 56px;
    width: 100%;
    position: relative;
    bottom: 0;
    margin-top: auto !important;
}