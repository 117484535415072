html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media (max-width: 768px) {
  body {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
} 