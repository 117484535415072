body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

/* Optional: Add a gradient background if desired */
.bg-light {
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%) !important;
}

/* Optional: Custom focus state for form controls */
.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.15);
  border-color: #007bff;
}

.login-container {
  min-height: 100vh;
  overflow: hidden;
}

.background-section {
  position: relative;
  height: 100vh;
  background-image: url('https://external-preview.redd.it/W5sR-KlQj4Hvwfg3LOWH3E5jKatc5xtvttAs1cujSLI.jpg?width=1080&crop=smart&auto=webp&s=68e2948a3fc1896163d311e77371a1ca7b47188b');
  background-size: cover;
  background-position: center;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3));
}

.form-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #fff;
}

.login-form-wrapper {
  width: 100%;
  max-width: 400px;
}

.login-form {
  padding: 1rem;
}

.login-form h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
}

.form-input {
  padding: 0.8rem 1rem;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  transition: all 0.3s ease;
}

.form-input:focus {
  border-color: #0d6efd;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.15);
}

.remember-me {
  color: #666;
}

.sign-in-button {
  padding: 0.8rem;
  border-radius: 8px;
  background-color: #0d6efd;
  border: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.sign-in-button:hover {
  background-color: #0b5ed7;
}

.forgot-password-link {
  color: #0d6efd;
  text-decoration: none;
  font-size: 0.9rem;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

/* Mobile Responsiveness */
@media (max-width: 991.98px) {
  .form-section {
    min-height: 100vh;
    background-image: url('https://external-preview.redd.it/W5sR-KlQj4Hvwfg3LOWH3E5jKatc5xtvttAs1cujSLI.jpg?width=1080&crop=smart&auto=webp&s=68e2948a3fc1896163d311e77371a1ca7b47188b');
    background-size: cover;
    background-position: center;
    position: relative;
  }

  .form-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.95);
  }

  .login-form-wrapper {
    position: relative;
    z-index: 1;
  }

  .login-form {
    background: white;
    padding: 2rem;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}

/* Error states */
.form-input.is-invalid {
  border-color: #dc3545;
}

.text-danger {
  color: #dc3545;
}
