.footer {
  width: 100%;
  text-align: center;
  border-top: 1px solid #e9ecef;
  background-color: #f8f9fa;
  padding: 0.8rem 0;
  margin-top: auto;
}

@media (max-width: 768px) {
  .footer {
    padding: 0.5rem 0;
  }
} 